import styled from "styled-components";
import { device } from "@retina-packages/retina-theme-bootstrap/packages/css/device";
import { font, color } from "../../../css/common/variables";

export const NoCostGeneticTestingWrapper = styled.div`
  .CTA-container {
    margin: 4.8rem 0 48px;
    position: relative;
    padding: 0 7px;

    @media ${device.ipadLandscapemin} {
      margin: 100px 0 50px;
    }
    @media ${device.desktopsignup} {
      padding: 0;
    }

    &:before {
      content: "";

      background-color: #00ffd9;
      position: absolute;
      top: 120px;
      left: 0px;
      height: calc(100% - 305px);
      width: 100%;

      @media ${device.desktopsignup} {
        height: calc(100% - 228px);
        padding: 170.5px 372.3px 20.5px 166.7px;
      }
    }
    .CTA {
      > .row {
        &:first-child {
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: #f7f7f7;
          padding-bottom: 1.4rem;
          position: relative;
          z-index: 10;
          @media ${device.desktopsignup} {
            padding-bottom: 3.1rem;
            max-width: 1168px;
            margin: auto;
          }

          &:before {
            content: "";
            width: 100%;
            height: 8px;
            background: linear-gradient(
              to right,
              #8000be 15%,
              #594dc6 34%,
              #3398ce 54%,
              #17d0d4 70%,
              #06f2d7 83%,
              #00ffd9 89%
            );
            box-shadow: none;
            @media ${device.desktopsignup} {
              height: 16px;
            }
          }
        }
      }
    }

    .genetic-para {
      font-size: 2.5rem;
      line-height: 1.2;
      font-weight: 700;
      font-family: ${font.gotham_bold};
      padding: 2.2rem 1.95rem;
      color: #000;

      @media ${device.ipadLandscapemin} {
        font-size: 2.2rem;
        line-height: 1.45;
      }
      @media ${device.desktopsignup} {
        padding-top: 8.8rem;
        font-size: 42px;
        line-height: 1.19;
        text-align: center;
      }
    }
    .o-paragraph {
      font-family: ${font.gotham_book};
      color: #000;
      font-weight: normal;
      letter-spacing: normal;
    }
    .o-para-disclaimer {
      font-size: 18px;
      line-height: 1.22;
      text-align: center;

      @media ${device.desktopsignup} {
        font-size: 22px;
        line-height: 1.45;
        padding-bottom: 7px !important;
      }

      span {
        font-family: ${font.gotham_bold};
        font-weight: bold;
      }
    }
    .o-para-avail {
      font-size: 14px;
      font-style: normal;
      line-height: 1.21;
      text-align: left;
    }
    .link-wrapper {
      padding-bottom: 1.9rem;
      .link-text {
        font-size: 1.8rem;
        line-height: 1.22;
        font-weight: 500;
        padding: 16px 24px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        background-color: rgb(128, 0, 190);
        color: #fff;
        border-radius: 6px;
        font-family: ${font.gotham};

        @media ${device.ipadLandscapemin} {
          font-size: 2.2rem;
          line-height: 1.1;
          padding: 20px 46px 20px 22px;
        }
        @media ${device.desktopsignup} {
          line-height: normal;
        }

        &:hover {
          background-color: #fff;
          color: rgb(128, 0, 190);
        }
      }
    }
  }
`;
export const AdvancingScienceCalloutWrapper = styled.div`
  max-width: 780px;
  margin: 40px auto 80px;
  text-align: center;

  @media ${device.laptopMedium} {
    margin: 40px auto 30px;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .o-header,
  .o-header--h3 {
    h2 {
      font-size: 3.4rem;
      font-weight: 600;
      line-height: 4.7rem;
      font-family: "Gotham";
      margin: 61px 0 30px;
      color: #8000be;

      @media ${device.laptopMedium} {
        font-size: 2.2rem;
        line-height: 3.2rem;
        margin: 54px 0 20px;
        text-align: center;
      }
    }
  }

  .o-text--book {
    p {
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 3.2rem;
      color: #000000;
      font-family: "Gotham-Book";
      margin: 0;

      @media ${device.laptopMedium} {
        font-size: 1.8rem;
        line-height: 2.6rem;
      }
    }
  }

  .o-button {
    p {
      margin: 0;

      a {
        box-shadow: 0 3px 4px #d6d6d6;
        margin: 30px 0;
        font-family: "Gotham";
        background: #8000be;
        color: #fff;
        border: 1px solid #fff;
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
        text-decoration: none;
        z-index: 1;
        position: relative;
        display: inline-block;
        vertical-align: top;
        overflow: hidden;
        padding: 10px 25px;
        letter-spacing: 0;
        border-radius: 6px;
        transition: all 0.3s ease;
        cursor: pointer;

        &:hover {
          background: #fff;
          color: #8000be;
          border-color: #fff;
          font-weight: 700;
          text-decoration: none;
        }

        &:after {
          content: "";
          display: inline-block;
          width: 24px;
          height: 18px;
          position: relative;
          top: 3px;
          left: 5px;
        }
      }
    }
  }
`;

export const AmericanDataCalloutWrapper = styled.div`
  .c-genetic--test {
    padding: 35px 60px;
    position: relative;
    background-color: #f7f7f7;
    box-shadow: 0 4px 6px #cecece;

    @media ${device.laptopMedium} {
      padding: 25px;
      margin: 40px 0;
    }

    &:before {
      content: "";
      width: 16px;
      height: 100%;
      display: block;
      background: linear-gradient(
        -180deg,
        #8000be 0%,
        #594dc6 35%,
        #17d0d4 64%,
        #00ffd9 100%
      );
      position: absolute;
      top: 0;
      right: 100%;
      box-shadow: 0 4px 6px #cecece;
    }

    div.o-paragraph {
      width: 100% !important;
      p {
        font-size: 2.2rem;
        font-weight: 500;
        line-height: 3.2rem;
        color: #000000;
        margin: 0;
        padding: 0;
        font-family: "Gotham";

        &:first-child {
          font-weight: unset;
          margin-bottom: 24px !important;
          @media ${device.laptopMedium} {
            font-size: 1.8rem !important;
            line-height: 2.2rem !important;
            font-family: "Gotham-Book", Arial, Helvetica, sans-serif;
          }
          strong {
            @media ${device.laptopMedium} {
              font-weight: 700 !important;
              font-family: "Gotham-Bold", Arial, Helvetica, sans-serif !important;
            }
          }
        }
        span {
          color: #8000be;
          text-transform: uppercase;
          font-weight: 500;
        }

        a {
          color: #8000be !important;
          text-decoration: underline;
          font-weight: 500;
          cursor: pointer;

          @media ${device.laptopMedium} {
            font-size: 1.8rem;
            line-height: 2.2rem;
          }
          @media ${device.desktopStart} {
            width: 90%;
            display: block;
          }
        }
      }
    }
  }

  .o-mb-80 {
    margin-bottom: 8rem;
  }

  .o-mt-80 {
    margin-top: 8rem;
  }

  .o-block--center {
    max-width: 950px;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const OverlappingInheritedRetinalDiseaseWrapper = styled.div`
  display: block;
  text-align: center;
  background-color: #8000be;
  padding: 88px 100px 0;

  @media ${device.laptopMedium} {
    padding: 1.6rem 1.6rem !important;
    max-width: 718px;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .video-section__inner-content {
    padding-bottom: 100px;

    @media ${device.laptopMedium} {
      padding-bottom: 20px;
    }

    .video-section__inner-content--white {
      background: #fff;
      padding: 88px 80px 80px;

      @media ${device.laptopMedium} {
        padding: 3rem 2.6rem 3.2rem 2.6rem;
      }

      .o-header,
      .o-header--h2 {
        h2 {
          padding-bottom: 40px;
          color: #8000be;
          font-size: 4.2rem;
          font-weight: 600;
          font-family: "Gotham";
          margin: 0;

          @media ${device.laptopMedium} {
            padding-bottom: 0;
            text-align: center;
            font-size: 2.5rem;
            line-height: 3rem;
          }
        }
      }

      .o-paragraph {
        p {
          color: #000000;
          font-size: 2.2rem;
          line-height: 3.2rem;
          text-align: center;
          max-width: 768px;
          margin: 0 auto;
          font-weight: 500;
          font-family: "Gotham";

          @media ${device.laptopMedium} {
            font-size: 1.4rem;
            line-height: 2.6rem;
            padding-top: 25px;
          }

          sup {
            position: relative;
            font-size: 60%;
            line-height: 0;
            vertical-align: baseline;
            top: -0.5em;
            font-family: "Gotham";
          }
        }
      }

      .video-section__image {
        margin: 20px 0 0;

        img {
          max-width: 100%;
        }
      }

      .image-caption {
        p {
          color: #000000;
          font-size: 1.6rem;
          line-height: 2rem;
          text-align: center;
          max-width: 768px;
          margin: 40px auto 0;
          font-family: "Gotham-Book";
          font-weight: 500;

          @media ${device.laptopMedium} {
            font-size: 1.4rem;
            padding-top: 25px;
            margin: 0 !important;
          }
        }
      }

      .o-button {
        p {
          margin: 0;

          a {
            box-shadow: 0 3px 4px #d6d6d6;
            margin: 40px 0;
            font-family: "Gotham";
            background: #8000be;
            color: #fff;
            border: 1px solid #fff;
            font-size: 2.2rem;
            font-weight: 500;
            line-height: 3.2rem;
            text-decoration: none;
            z-index: 1;
            position: relative;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            padding: 10px 25px;
            letter-spacing: 0;
            border-radius: 6px;
            transition: all 0.3s ease;
            cursor: pointer;

            @media ${device.laptopMedium} {
              margin: 32px 0 10px 0;
              font-size: 1.8rem;
              line-height: 2.6rem;
            }

            &:hover {
              background: #fff;
              color: #8000be;
              border-color: #fff;
              font-weight: 700;
            }

            &:after {
              content: "";
              display: inline-block;
              width: 24px;
              height: 18px;
              position: relative;
              top: 3px;
              left: 5px;

              @media ${device.laptopPros} {
                display: block;
                margin: 10px auto 0;
                left: 0;
                top: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const HcpFooterBlockWrapper = styled.div`
  .footer-block {
    padding: 0 100px;
    display: flex;
    &.footer-block-bg {
      background-color: #8000be;
    }

    @media ${device.ipadLandscape} {
      padding: 0 20px;
      display: block;
      margin-top: -1px;
      margin-bottom: -265px;
    }

    .footer-block--row {
      margin: auto;
      display: flex;

      @media ${device.ipadLandscape} {
        display: block;
      }

      .footer-inner-block {
        border-radius: 10px;
        background-color: #fff;
        padding: 25px 20px 120px;
        position: relative;
        margin: 0 15px;
        z-index: 992;
        box-shadow: none;

        @media ${device.ipadLandscape} {
          width: 100%;
          margin: 30px auto 0;
          padding: 16px 20px 30px;
        }

        &:first-child {
          margin-left: 0;

          @media ${device.ipadLandscape} {
            margin-left: auto;
            margin-top: 0;
          }
        }

        &:last-child {
          margin-right: 0;
        }

        &:before {
          content: "";
          background: #00ffd9;
          width: 100%;
          height: 16px;
          position: absolute;
          top: 0;
          left: 0;
          border-radius: 9px 9px 0 0;
        }

        .footer-inner-block__small-icon {
          img {
            margin: auto;
            text-align: center;
            display: block;
            padding: 10px 0 20px 0;
            height: 100px;
            width: auto;
            max-width: 100%;

            @media ${device.laptopMedium} {
              padding: 16px 0 16px 0;
              height: auto;
            }
          }
        }

        .o-common-title {
          p {
            margin: 0;
            font-weight: 700;
            min-height: unset !important;
            font-size: 2.7rem;
            line-height: 3.8rem;
            color: #8000be;
            text-align: center;

            @media ${device.ipadLandscape} {
              font-size: 2rem;
              line-height: 2.2rem;
            }
          }
        }

        .o-common-para {
          p {
            margin: 0;
            text-align: center;
            padding: 24px 0 0 0;
            font-family: "Gotham-Book";
            font-size: 2.2rem;
            line-height: 3.2rem;
            color: #000000;

            @media ${device.ipadLandscape} {
              font-size: 1.8rem;
              line-height: 2.6rem;
            }
          }
        }

        .o-button {
          p {
            margin: 0;

            a {
              position: absolute;
              width: 90%;
              bottom: 25px;
              text-align: center;
              box-shadow: 0 3px 4px #d6d6d6;
              display: block;
              justify-content: center;
              align-items: center;
              font-size: 1.9rem;
              background: #8000be;
              color: #fff;
              border: 1px solid #fff;
              font-family: "Gotham";
              font-weight: 500;
              line-height: 3.2rem;
              text-decoration: none;
              z-index: 1;
              vertical-align: top;
              overflow: hidden;
              padding: 10px 25px;
              letter-spacing: 0;
              border-radius: 6px;
              transition: all 0.3s ease;
              cursor: pointer;

              @media ${device.ipadLandscape} {
                position: relative;
                left: 0;
                bottom: 0;
                margin-top: 32px;
                width: 100%;
                display: block;
                padding: 10px;
                font-size: 18px;
                line-height: 2.6rem;
              }

              &:hover {
                background: #fff;
                color: #8000be;
                border-color: #fff;
                font-weight: 700;
                text-decoration: none;
              }

              &:after {
                content: "";
                display: inline-block;
                width: 24px;
                height: 18px;
                position: relative;
                top: 2px !important;
                left: 5px;

                @media ${device.ipadLandscape} {
                  display: block;
                  margin: 10px auto 0;
                  left: 0;
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;
