import React from 'react'
import Layout from "@retina-packages/retina-theme-bootstrap/packages/components/layout"
import { graphql } from 'gatsby'
import { PageWrapper, HcpHomePageWrapper, HcpPage } from '@retina-packages/retina-theme-bootstrap/packages/css/CommonStyle'
import SimpleSlider from '@retina-packages/retina-theme-bootstrap/packages/components/Carousels/SlickCarousel'
import TextCarousel from '@retina-packages/retina-theme-bootstrap/packages/components/Carousels/TextCarousel'
import MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer'
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType'
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders'
import { CarouselResponceData } from '@retina-packages/retina-theme-bootstrap/packages/types/ImageCarouselType'
import retinaConfig from '../../../utils/retinaConfigs'
import { HTMLRenderer } from '@retina-packages/retina-theme-bootstrap'
import Video from "@retina-packages/retina-theme-bootstrap/packages/images/dna-video-bg.mp4"
import { AdvancingScienceCalloutWrapper, AmericanDataCalloutWrapper, HcpFooterBlockWrapper, OverlappingInheritedRetinalDiseaseWrapper, NoCostGeneticTestingWrapper } from './styles'
import { HCPHome } from './constants'
import { deriveSiteLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder, textCarouselBuilder } from '../../../utils'
/** Rendering template for HCP Home Page
 *
 * @param props props
 * @returns
 */
const HCPHomeTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso
  const pgContextsHM = props.pageContext
  const htmlStaticFilesHM = {
    nonSVGImages: pgContextsHM.nonSVGImages,
    svgMediaImages: pgContextsHM.svgMediaImages,
    allMediaDocument: pgContextsHM.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: HCPHome.mainMenu, siteFooterMenu: HCPHome.footerMenu, addHCPPrefix: retinaConfig.addHcpPrefix })

  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const bannerTitle = ""
  const carouselTitle = HCPHome.carousel
  const footerTitle = HCPHome.footer
  const exitPopupTitle = HCPHome.exitPopup
  const hcpSwitcherTitle = HCPHome.hcpSwitcher
  const topNavTitle = HCPHome.topNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs,
    carouselData
  }: {
    footerText: string
    exitData: exitPopDataType
    hcpSwitcher: exitPopDataType
    topNavs: string
    carouselData: CarouselResponceData
  } = reusableDataBuilders({ blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle })

  const americanAcademyData = fullHTMLDataBuilder({ blocks, title: HCPHome.americalData })

  const footerCallOutData: string = fullHTMLDataBuilder({ blocks, title: HCPHome.footerCallout })

  const carouselSlideData: CarouselResponceData = textCarouselBuilder({ blocks, title: HCPHome.textCarousel })

  const advanceScienceDesc = fullHTMLDataBuilder({ blocks, title: HCPHome.advancingScience })

  const overlappData: string = fullHTMLDataBuilder({ blocks, title: HCPHome.overlap })

  const hcpHomeReference = footRefHTMLBuilder({ blocks, title: HCPHome.footRef })

  const siteLogos: any = deriveSiteLogo({ blocks, title: HCPHome.siteLogo })

  return (
    <div className='hcppage'>
      <HcpPage className="hcphomepagewrap">
        <HcpHomePageWrapper>
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            footref={hcpHomeReference}
            exitPopData={exitData}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            staticMediaFiles={htmlStaticFilesHM}
            siteLogos={siteLogos}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={HCPHome.backToTopGTM}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            modalCloseButtonGTMLabel={retinaConfig.modalCloseButtonGTMLabel}
            footerClassName={HCPHome.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... { ...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs }}
          >
            <PageWrapper className='pagewrapper hcp-homepage'>
              {carouselData && (
                <SimpleSlider data={carouselData} {...HCPHome.carousalAttrs} simpleImage={true} />
              )}
              <div className='o-container hcp-home-footer'>
                <AmericanDataCalloutWrapper className="hcpamerican">
                  <HTMLRenderer html={americanAcademyData} data={htmlStaticFilesHM} tagName={'div'} />
                </AmericanDataCalloutWrapper>
              </div>
              <div className='o-container hcp-home-footer'>
                <TextCarousel carouselSlideData={carouselSlideData} Video={Video} {...HCPHome.videoCarousalAttrs} />
                <AdvancingScienceCalloutWrapper className='c-advance-scienece o-text-center'>
                  <HTMLRenderer html={advanceScienceDesc} data={htmlStaticFilesHM} tagName={'div'} />
                </AdvancingScienceCalloutWrapper>
                <OverlappingInheritedRetinalDiseaseWrapper className='video-section o-inner video-section-home o-top-space'>
                  <HTMLRenderer html={overlappData} data={htmlStaticFilesHM} tagName={'div'} className={'video-section__inner-content'} />
                </OverlappingInheritedRetinalDiseaseWrapper>
              </div>
              <HcpFooterBlockWrapper className='o-container hcp-home-footer'>
                <HTMLRenderer html={footerCallOutData} data={htmlStaticFilesHM} tagName="div" className='footer-block footer-block-bg' />
              </HcpFooterBlockWrapper>
            </PageWrapper>
          </Layout>
        </HcpHomePageWrapper>
      </HcpPage>
    </div>
  )
}

export default HCPHomeTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ... HCPHomePageQuery
      }
    }
  }
`
