export const HCPHome: any = {
  mainMenu: "Main Navigational Menu HCP GCSO - Retina Global",
  footerMenu: "Footer Navigation Menu HCP GCSO - Retina Global",
  carousel: "Homepage Carousal HCP - Retina Global",
  footer: "Retina Global Footer HCP - US - Global",
  exitPopup: "Retina Canada Exit Popup New - Global",
  hcpSwitcher: "HCP Switcher Modal Pop Up - Global",
  topNav: "Top Navigational Menu HCP US - Global",
  infographic: "Infographic Percentage Home Consumer GCSO  - Global",
  footrCallout: "Home Footer Callout - HTML DTC US Global",
  siteLogo: "Site logos - Global",
  americalData: "American Academy of Ophthalmology HTML - Retina Global",
  footerCallout: "HCP Homepage Footer Navigation Callout HTML - Retina Global",
  advancingScience: "Advancing Science HTML - Retina Global",
  overlap: "Overlapping presentations of IRD HTML - Retina Global",
  footRef: "HCP Home Reference - Retina Global",
  textCarousel: "DNA Video and Carousel - Retina Global",
  backToTopGTM: "Back to top - Homepage",
  carousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'Hero image - play - hcp',
      'aria-label': 'Play slideshow'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'Hero image - pause - hcp',
      'aria-label': 'Pause slideshow'
    },
    playBtnAriaDescription: ' ',
    pauseBtnAriaDescription: ' '
  },
  videoCarousalAttrs: {
    playBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'New gene variants - play',
      'aria-label': 'Play slideshow'
    },
    pauseBtnGTM: {
      'className': 'gtm-link-internal',
      'data-gtm-event-label': 'New gene variants - pause',
      'aria-label': 'Pause slideshow'
    }
  },
  footerClassName: "home-footer"
}